export const menuItems = [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/about",
    text: "About Us",
  },
  {
    path: "/contractvehicles",
    text: "Contract Vehicles",
  },
  {
    path: "/products",
    text: "projects",
  }
  
]
