export const menuItems = [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/about",
    text: "about",
  },
  {
    path: "/contractvehicles",
    text: "Contract Vehicles",
  }
]
